import {useState, useEffect} from "react";


export const useIsMobile = width => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= width);

    useEffect(() => {
        window.addEventListener("resize", () => setIsMobile(window.innerWidth <= width));

        return () => window.removeEventListener("resize", () => setIsMobile(window.innerWidth <= width));
    }, [width]);

    return isMobile;
};