import {useCallback, useState} from "react";
import axios from "axios";
import {useSnackbar} from "notistack";


export const GetHeaders = (isMultipart = false) => {
    const api_key = JSON.parse(localStorage.getItem('api_key') || "null");
    let headers = {};

    headers["Accept"] = "application/json";
    headers["Cache-Control"] = "no-cache";
    headers["Content-Type"] = isMultipart ? "multipart/form-data" : "application/json, text/plain, */*";

    if(api_key) {
        headers["Authorization"] = "Basic " + api_key;
    }

    return headers;
};

export const useRequest = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const request = useCallback(async (options, onSuccess, onError) => {
        setLoading(true);

        const axiosConfig = {
            ...options,
            headers: GetHeaders(options.isMultipart)
        };

        try {
            const response = await axios(axiosConfig);

            setLoading(false);

            if(onSuccess) onSuccess(response.data);

            return response.data;
        } catch (e) {
            setLoading(false);

            if(e.response) {
                if(onError) onError(e);

                if(e.isAxiosError) {
                    if(e.response) {
                        setErrors([`${'Ошибка'} ${e.response.status}: ${e.response.statusText}`]);
                        enqueueSnackbar(`${'Ошибка'} ${e.response.status}: ${e.response.statusText}`, {variant: 'error'});
                    }
                }
            } else {
                setErrors(['Что-то пошло не так...']);
                enqueueSnackbar('Что-то пошло не так...', {variant: 'error'});
            }
        }
    }, [enqueueSnackbar]);

    const clearError = useCallback(() => setErrors([]), []);

    return {loading, errors, request, clearError};
};