import {useState, useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {sortByPosition} from "../utils/sort";
import {useRequest} from "./request.hook";


export const useMenuParams = () => {
    const [loading, setLoading] = useState(true);
    const [menuCategories, setMenuCategories] = useState([]);
    const {request} = useRequest();
    const {category_id} = useParams();
    const searchData = new URLSearchParams(useLocation().search);
    const searchLetter = searchData.get('abc');
    const prevCategory = +searchData.get('prev_cat');

    useEffect(() => {
        request({url: '/api/v1/wiki/menu'})
            .then(data => {
                if(data) {
                    const newCategories = sortByPosition(data)
                        .map(item => ({
                            ...item,
                            sub_menu: sortByPosition(item.sub_menu).map(item => ({
                                ...item,
                                sub_menu: sortByPosition(item.sub_menu)
                            }))
                        }));

                    setMenuCategories(newCategories);
                }
                setLoading(false);
            });
    }, []);

    let activeCategory = null;
    let activeParentSubCategory = null;
    let activeParentCategory = null;

    if(category_id && menuCategories.length) {
        const activeCat = menuCategories.find(cat => cat.id === +category_id);

        if(activeCat) {
            activeParentCategory = null;

            activeCategory = {
                id: activeCat.id,
                title: activeCat.title
            };
        } else {
            menuCategories.forEach(cat => {
                const activeCat = cat.sub_menu.find(item => item.id === +category_id);

                if(activeCat) {
                    activeParentCategory = {
                        id: cat.id,
                        title: cat.title
                    };

                    activeCategory = {
                        id: activeCat.id,
                        title: activeCat.title
                    };
                } else {
                    cat.sub_menu.forEach(subCat => {
                        const activeCat = subCat.sub_menu.find(item => item.id === +category_id);

                        if(activeCat) {
                            activeParentCategory = {
                                id: cat.id,
                                title: cat.title
                            };

                            activeParentSubCategory = {
                                id: subCat.id,
                                title: subCat.title
                            };

                            activeCategory = {
                                id: activeCat.id,
                                title: activeCat.title
                            };
                        }
                    });
                }
            });
        }
    }

    return {loading, menuCategories, activeCategory, activeParentSubCategory, activeParentCategory, searchLetter, prevCategory};
};