import {Suspense} from "react";
// import {hydrate} from "react-dom"; # REACT-SNAP
import ReactDOM from "react-dom/client";
import Loading from "./components/Loading";
import App from "./App";
import "./index.css";

const WrappedApp = () => (
    <Suspense fallback={<Loading/>}>
        <App/>
    </Suspense>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<WrappedApp />);

// const rootElement = document.getElementById('root');
// const root = ReactDOM.createRoot(rootElement);

// if (rootElement.hasChildNodes()) {
//     hydrate(<WrappedApp />, rootElement);
// } else {
//     root.render(<WrappedApp />);
// }