import {createContext} from "react";


export const MenuContext = createContext({
    loading: true,
    menuCategories: [],
    activeCategory: null,
    activeParentSubCategory: null,
    activeParentCategory: null,
    searchLetter: '',
    prevCategory: null
});